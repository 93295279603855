<template>
    <div class="customer-detail" v-loading="isLoading">
        <div class="customer-info">
            <div class="avatar">
                <img v-default-avatar v-imgCDN :src="customerDetailData.wx_avatar" />
            </div>
            <div class="name">
                <h4>{{ customerDetailData.wx_nickname }}</h4>
                <div>{{ stateDict[state] ? stateDict[state](customerDetailData.protect_days) : '' }}</div>
                <!--div v-if="customerDetailData.is_valid && customerDetailData.protect_days > 0">
          <span class="days">{{ customerDetailData.protect_days }}天内注册店铺成为商机</span>
        </div>
        <div v-else>访客</div>-->
            </div>
            <!-- <div class="effective-customer" v-if="customerDetailData.is_valid">
              <span>有效客户</span>
            </div> -->
        </div>
        <div class="recently-visited">
            <div>
                <span>最近访问时间</span>
                <span>{{ handleTime(customerDetailData.last_accessed_time) }}</span>
            </div>
        </div>
        <div class="record" v-if="activityRecord.length">
            <p>活动参与记录</p>
            <div class="record-item" v-for="(item, idx) in activityRecord" :key="idx">
                <div>
                    <span class="activity">{{ item.activity_title }}</span>
                    <span>{{ item.join_at }}</span>
                </div>
            </div>
        </div>
        <div class="record" v-if="bindRecord.length">
            <p>关系记录</p>
            <div class="record-item" v-for="(item, idx) in bindRecord" :key="idx">
                <div>
                    <span>{{ item.event_title }}</span>
                    <span>{{ item.change_at }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    fetchCustomerDetail,
    fetchCustomerRelationChange,
    fetchCustomerActivityParticipate
} from '@/views/staffPromotion/api';

export default {
    data() {
        return {
            isEffectiveCustomer: true,
            bindRecord: [],
            activityRecord: [],
            customerDetailData: [],
            isLoading: false,
            stateDict: {
                0: () => '分享锁定微信',
                1: (day) => (day ? `锁定微信剩余有效期${day}天` : ''),
                5: () => '已被他人锁客'
            },
            state: ''
        };
    },
    computed: {
        showProtectDays() {
            let status = true;
            this.customerDetailData.expire_days === this.customerDetailData.protect_days
                ? (status = false)
                : (status = true);
            return status;
        }
    },
    mounted() {
        this.initCustomerDetail();
        this.initCustomerChangeLog();
        this.initCustomerActivityPartLog();
    },
    methods: {
        initCustomerDetail() {
            this.isLoading = true;
            const { customer_id, state } = this.$route.query;
            this.state = state;
            const params = {
                customer_id
            };
            fetchCustomerDetail(params)
                .then((res) => {
                    this.isLoading = false;
                    this.$toast.clear();
                    this.customerDetailData = res.data;
                })
                .catch((err) => {
                    console.log('err: ', err);
                    this.isLoading = false;
                    this.$toast.fail('获取用户信息失败');
                });
        },
        initCustomerChangeLog() {
            const params = {
                customer_id: this.$route.query.customer_id
            };
            fetchCustomerRelationChange(params).then((res) => {
                this.bindRecord = res?.data.list;
            });
        },
        initCustomerActivityPartLog() {
            const params = {
                customer_id: this.$route.query.customer_id
            };
            fetchCustomerActivityParticipate(params).then((res) => {
                this.activityRecord = res?.data?.list;
            });
        },
        handleTime(time) {
            let nowYear = new Date().getFullYear();
            let year = time && time.substring(0, 4);
            if (nowYear === Number(year)) {
                return time.slice(5);
            } else {
                return time;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.customer-detail {
    .customer-info,
    .recently-visited {
        background: #fff;

        div {
            span:nth-child(2) {
                color: #999999;
            }
        }
    }

    .customer-info {
        margin-top: 0;
        display: flex;
        align-items: center;
        height: 1.9rem;
        margin-bottom: 0.16rem;

        & > .avatar {
            margin-left: 0.32rem;
            width: 15%;

            img {
                width: 1.12rem;
                height: 1.12rem;
                border-radius: 50%;
            }
        }

        & > .name {
            margin-left: 0.24rem;
            width: 70%;

            h4 {
                font-size: 0.32rem;
                margin: 0;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
            }

            div {
                color: #999999;
                font-size: 0.26rem;
                margin-top: 0.1rem;

                span {
                    color: #333333;
                    font-size: 0.26rem;
                }

                p {
                    display: inline-block;
                }
            }
        }

        & > .effective-customer {
            margin-right: 0.32rem;
            width: 15%;
            margin-top: -0.6rem;

            span {
                display: table-cell;
                vertical-align: middle;
                min-width: 1.1rem;
                height: 0.28rem;
                background: #fff;
                color: #11d611;
                border: 0.02rem solid #11d611;
                border-radius: 2px;
                font-size: 0.2rem;
                text-align: center;
                line-height: normal;
                padding: 0.02rem 0;
                // line-height: 0.34rem;
            }
        }
    }

    .record-item,
    .recently-visited {
        & > div {
            display: flex;
            justify-content: space-between;
            // border-bottom: 0.02rem solid rgba(0, 0, 0, 0.08);
            box-shadow: inset 0 -0.5px 0 0 rgba(0, 0, 0, 0.08);
            height: 0.96rem;
            line-height: 0.96rem;
            font-size: 0.26rem;
            color: #333;
        }
    }

    .recently-visited {
        margin-bottom: 0.28rem;
        padding: 0 0.28rem;

        & > div:last-child {
            border: none;
        }
    }

    .record {
        background: #fff;
        margin-bottom: 0.28rem;
        padding: 0 0.28rem;
        font-size: 0.28rem;
        color: #333333;

        p {
            height: 0.48rem;
            line-height: 0.68rem;
            font-size: 0.32rem;
            padding-top: 0.2rem;
            margin-bottom: 0.16rem;
            color: #000;
        }

        .record-item {
            & > div {
                font-size: 0.26rem;

                span:nth-child(2) {
                    color: #999999;
                }
            }
        }

        .record-item:last-of-type {
            & > div {
                border: none;
            }
        }
    }

    .days {
        margin-right: 0.2rem;
    }

    .source,
    .activity {
        width: 5rem;
        text-align: left;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
    }

    .activity {
        width: 4rem;
    }
}
</style>
